import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withInitiator } from '@src/api/util';
import { getLogger } from '@src/log-util';
import { isNullish } from '@src/util';
import { InferGetServerSidePropsType } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { getCsrfToken, signIn } from 'next-auth/react';

import SmsCode from '../components/SmsCode';

// TODO change this to use the signIn method with callbackUrl
// respect the callbackUrl query param on the signIn page, if provided
// https://next-auth.js.org/configuration/pages#credentials-sign-in
// https://next-auth.js.org/getting-started/client#specifying-a-callbackurl

interface PageProps {
  csrfToken?: string;
}

export const getServerSideProps = withInitiator<PageProps>(async (context) => {
  const logger = getLogger('page.login.api');
  logger.info('Hello from the page');
  return {
    props: {
      csrfToken: await getCsrfToken(context),
    },
  };
});

export default function Login({ csrfToken = '' as string }: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const isSmall = useMediaQuery('(max-width:600px)');
  const router = useRouter();
  const {
    query: { error },
  } = router;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessageUsername] = useState('Please enter your email address');
  const [errorMessagePassword] = useState('Please enter your password');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [flow, setFlow] = useState('login');
  const [errorOpen, setErrorOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const handleErrorClose = (): void => {
    setErrorOpen(false);
  };

  /*
  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    // event.preventDefault();
    logger.info('Login submit');
    setSpinner(true);
  }
  */

  async function handleLogin(): Promise<void> {
    setSpinner(true);

    const response = await fetch('/api/sec/authenticate-user', {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
    const body = await response.json();

    if (response.ok) {
      switch (body.type) {
        case 'error':
          setErrorMessage(body.errorMessage);
          break;
        case 'newPasswordRequired':
          setFlow('newPassword');
          break;
        case 'success':
          setFlow('code');
          break;
        default:
          setErrorMessage('Unknown error during the login');
          break;
      }
    } else {
      setErrorMessage(body.message ?? 'Unknown error during the login');
    }

    setSpinner(false);
  }

  if (isSmall) {
    return (
      <Box>
        Sorry, this page is not available on mobile devices. Please use a desktop or tablet to access this page.
      </Box>
    );
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        backgroundImage: 'linear-gradient(225.27deg, #3170C8 9.25%, #22A3FD 44.26%, #00D2FF 81.53%);',
      }}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        {flow !== 'code' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100vh',
              justifyContent: 'center',
            }}>
            <Paper
              sx={{
                py: 4,
                px: isSmall ? 4 : 6,
                borderRadius: '30px',
                display: 'grid',
                gridTemplateColumns: isSmall ? 'min(300px, 100%)' : '1fr 300px',
                gridColumnGap: isSmall ? 0 : '2rem',
                pb: isSmall ? 4 : 0,
                pt: isSmall ? 4 : 6,
              }}
              elevation={2}>
              {!isSmall && (
                <Grid container flexDirection="column" flexWrap="nowrap" justifyContent="space-between">
                  <Box sx={{ pl: '40px', mb: 2 }}>
                    <Image src="/images/skriptLogo.svg" width={115} height={45} alt="" />
                  </Box>
                  <Box sx={{ marginBottom: '-26px' }}>
                    <Image src="/images/loginImg.svg" width={366} height={322} alt="" />
                  </Box>
                </Grid>
              )}
              <Grid
                container
                flexDirection="column"
                flex="1 0 minmax(300px, 90vw)"
                justifyContent="flex-end"
                alignItems="center"
                flexWrap="nowrap"
                sx={{ pb: isSmall ? 0 : 4 }}>
                {isSmall ? (
                  <Box sx={{ display: 'grid', alignSelf: 'flex-start' }}>
                    <Image src="/images/skriptLogo.svg" width={115} height={45} alt="" />
                    <Typography component="h1" variant="h2" fontWeight={600} color="secondary">
                      Skript Portal
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    component="h1"
                    variant="h2"
                    fontWeight={600}
                    color="secondary"
                    sx={{ alignSelf: 'flex-start' }}>
                    Developer Portal
                  </Typography>
                )}
                <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
                  <TextField
                    margin="normal"
                    required
                    id="username"
                    label="Email Address"
                    name="username"
                    autoComplete="off"
                    autoFocus
                    fullWidth
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    error={errorOpen && username === ''}
                    helperText={errorOpen && errorMessageUsername}
                  />
                  <TextField
                    margin="normal"
                    required
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="off"
                    fullWidth
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    error={errorOpen && password === ''}
                    helperText={errorOpen && errorMessagePassword}
                  />
                  <Link href="/forgot-password" sx={{ mt: 2, textDecoration: 'none', color: '#888' }}>
                    Forgot password?
                  </Link>
                  {errorOpen && (
                    <Typography color="error" align="center" sx={{ mt: 2 }}>
                      *Please complete all required fields
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    onClick={() => {
                      handleLogin();
                    }}
                    variant="contained"
                    size="large"
                    sx={{ my: 2, textTransform: 'none', fontWeight: 600 }}
                    fullWidth
                    disabled={spinner}>
                    {spinner ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
                  </Button>
                  <Typography sx={{ color: '#888' }}>
                    Don&apos;t have an account yet?{' '}
                    <Link href="/signup/details" sx={{ textDecoration: 'none' }}>
                      Sign Up
                    </Link>
                  </Typography>
                  <Snackbar
                    open={errorOpen}
                    autoHideDuration={6000}
                    onClose={() => {
                      handleErrorClose();
                    }}>
                    <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
                      Login error - {error}
                    </Alert>
                  </Snackbar>
                </Box>
              </Grid>
            </Paper>
          </Box>
        ) : (
          <SmsCode
            title="Confirm it's you"
            subtitle="Please enter the 6 digit authentication code that was sent to your phone number."
            loading={spinner}
            actions={{
              signIn: async (code: string) => {
                setSpinner(true);
                const signInResponse = await signIn('code-login', {
                  callbackUrl: '/',
                  redirect: false,
                  username,
                  password,
                  code,
                });

                if (!isNullish(signInResponse?.error)) {
                  setSpinner(false);
                  try {
                    const errorBlock = JSON.parse(signInResponse?.error ?? '');
                    setErrorMessage(errorBlock.errorMessage);
                  } catch (e) {
                    setErrorMessage('Error during code verification');
                  }
                } else if (!isNullish(signInResponse?.ok)) {
                  // this should happen automatically due to the useEffect implementation
                  router.push(signInResponse?.url ?? '/');
                }
              },
              resend: async () => {
                setSpinner(true);

                const response = await fetch('/api/sec/resend-code', {
                  method: 'POST',
                  body: JSON.stringify({
                    username,
                    password,
                  }),
                  headers: { 'Content-Type': 'application/json' },
                });
                const body = await response.json();

                setSpinner(false);

                if (!response.ok) {
                  setErrorMessage(body.message);
                }
              },
            }}
          />
        )}
        <Snackbar
          open={!(errorMessage == null)}
          autoHideDuration={6000}
          onClose={() => {
            handleErrorClose();
          }}>
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
            Login error - {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Grid>
  );
}

Login.auth = false;
